#backToTop {
  position: fixed;
  bottom: 30px;
  width: 50px;
  height: 50px;
  background-color: hsla(var(--black-hsl), 1);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 12001;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.back-to-top-arrow path {
  stroke: hsla(var(--white-hsl), 1);
}
/* Position variants */
#backToTop.position-right {
  right: 30px;
}
#backToTop.position-left {
  left: 30px;
}
#backToTop.position-middle {
  left: 50%;
  transform: translateX(-50%);
}
#backToTop.visible {
  opacity: 1;
}
#backToTop svg:first-child {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
}
#backToTop svg:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
}
#backToTop svg:nth-child(2) .scroll-ring {
  stroke: white;
}
/* Ensure middle position works with the progress circle */
#backToTop.position-middle svg:first-child {
  transform: translate(-50%, -50%);
}
